<template>
  <div class="e-container">
    <template v-if="exams.length === 0">
      <div class="e-empty-container">
        <el-empty description="暂时没有新的考试"></el-empty>
      </div>
    </template>
    <div class="e-cards-container">
      <div v-for="(item, i) in exams" :key="item.id"
        :class="i === 0 ? 'e-first-card-container card-large' : 'card-small'">
        <el-card class="exam-card">
          <div class="exam-content">
            <div class="exam-title">
              <div class="exam-tags">
                <el-tag size="small" type="warning" effect="dark">{{ examTypeMap[item.type] }}</el-tag>
                <span class="exam-owner">安徽省人工智能学会</span>
              </div>
              <span>{{ item.name }}</span>
            </div>
            <div class="exam-time">
              <div>
                <span>开始时间：</span>
                <span>{{ item.startDttm }}</span>
              </div>
              <div>
                <span>结束时间：</span>
                <span>{{ item.endDttm }}</span>
              </div>
            </div>
            <div class="exam-opt">
              <template v-if="examWaitingState[item.id] <= 0">
                <el-button type="primary" @click="goExam(item)">开始作答</el-button>
              </template>
              <template v-else>
                <el-button type="primary" plain disabled>开始作答</el-button>
              </template>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'studentExams',
  data() {
    return {
      examTypeMap: {
        1: '正式考试',
        2: '模拟考试',
      },
      examWaitingState: {},
      exams: [],
    };
  },
  async mounted() {
    const exams = await this.queryExams();
    const examWaitingState = [];
    for (let i = 0; i != exams.length; i++) {
      const examDeadline = await this.queryExamTime(exams[i]);
      examWaitingState[examDeadline.examId] = examDeadline.startLeftSeconds;
    }
    this.waitingExams(examWaitingState);
    this.exams = exams;
    if (this.exams === null || this.exams.length === 0) {
      this.$message({
        type: 'warning',
        message: '没有新的考试',
      });
    }
  },
  methods: {
    async queryExams() {
      // 查询考试列表
      const result = await this.Api.student.queryExamList();
      if (!result.success) {
        this.$message({
          type: 'error',
          message: '网络错误，请稍后重试',
        });
        return [];
      }
      return result.data;
    },
    async queryExamTime(exam) {
      // 查询考试时间
      const result = await this.Api.student.queryExamDeadline({ id: exam.id });
      if (!result.success) {
        return {
          examId: exam.id,
          startLeftSeconds: 0,
        };
      }
      return result.data;
    },
    waitingExams(examWaitingState) {
      setInterval(() => {
        const newExamWaitingState = {};
        for (let examId in examWaitingState) {
          newExamWaitingState[examId] = examWaitingState[examId]--;
        }
        this.examWaitingState = newExamWaitingState;
      }, 1000);
    },
    goExam(exam) {
      this.fullscreenCheck();
      this.$router.push({
        path: '/student/exam',
        query: {
          id: exam.id,
        },
      });
    },
    fullscreenCheck() {
      if (!document.fullscreenElement &&
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.e-container {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 auto;

  .e-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    .e-first-card-container {
      margin: 55px 0;
      width: 1200px;
      display: flex;
      justify-content: center;
    }
  }

  .e-empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.card-large {
  transform: scale(1.2);
  transform-origin: center center;
}

.card-small {
  transform: scale(0.8);
  transform-origin: center center;
  margin: 0 8px;
}

.exam-card {
  width: 380px;
  height: 270px;

  .exam-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 25px;

    .exam-title {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      font-size: 21px;

      .exam-tags {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .exam-owner {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    .exam-time {
      font-size: 17px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .exam-opt {
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
